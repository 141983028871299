import React from "react";
import { Link } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faEllipsisH, faAngleRight } from '@fortawesome/free-solid-svg-icons'

// H/T to the guide at:
//   https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/

const DOTS = '...'

const range = (start, end) => {
  const length = end - start + 1

  return Array.from({ length }, (_, idx) => idx + start)
}

function computePagination(totalPages, currentPage, siblingCount = 1) {
  const totalPageNumbers = siblingCount + 5

  if (totalPageNumbers >= totalPages) {
    return range(1, totalPages)
  }

  const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
  const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPages)

  const shouldShowLeftDots = leftSiblingIndex > 2
  const shouldShowRightDots = rightSiblingIndex < totalPages - 2

  const firstPageIndex = 1
  const lastPageIndex = totalPages

  if (!shouldShowLeftDots && shouldShowRightDots) {
    const leftItemCount = 3 + 2 * siblingCount
    const leftRange = range(1, leftItemCount)

    return [...leftRange, DOTS, totalPages]
  }

  if (shouldShowLeftDots && !shouldShowRightDots) {
    const rightItemCount = 3 + 2 * siblingCount
    const rightRange = range(totalPages - rightItemCount + 1, totalPages)

    return [firstPageIndex, DOTS, ...rightRange]
  }

  if (shouldShowLeftDots && shouldShowRightDots) {
    const middleRange = range(leftSiblingIndex, rightSiblingIndex)
    return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
  }
}

/**
 * Render pagination controls.
 *
 * @param {object} props Properties object containing a totalPages and currentPage.
 * @returns React element containing a post.
 */
function Pager(props) {  
  const {
    totalPages,
    currentPage,
    siblingCount = 1,
    pageLinkPrefix,
    pageOneLink
  } = props

  const numericCurrentPage = parseInt(currentPage)
  const paginationRange = computePagination(totalPages, numericCurrentPage, siblingCount)

  // Figure out how many pages to show.
  if (numericCurrentPage === 0 || paginationRange.length < 2) {
    return null // Don't render if no pages to show.
  }

  return (
    <div className="pagination-container flex flex-col items-center my-12">
      <div className="flex text-gray-700">
        {/* Left navigation arrow */}
        {
          (numericCurrentPage > 1 ? 
            (numericCurrentPage > 2 ?
              (
                <Link to={`${pageLinkPrefix}/${numericCurrentPage - 1}`} key="left-arrow">
                  <div className="pagination-item flex w-12 h-12 rounded-full bg-gray-200 justify-center items-center hover:bg-blue-600 hover:text-white hover:md-shadow">
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </div>
                </Link>
              )
              :
              (
                <Link to={pageOneLink} key="left-arrow">
                  <div className="pagination-item flex w-12 h-12 rounded-full bg-gray-200 justify-center items-center hover:bg-blue-600 hover:text-white hover:md-shadow">
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </div>
                </Link>
              )
            )
            : (
              <div className="pagination-item flex w-12 h-12 rounded-full bg-gray-200 justify-center items-center" key="left-arrow">
                <FontAwesomeIcon icon={faAngleLeft} className="opacity-25"/>
              </div>
            )
          )
        }
        

        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <div className="pagination-item dots flex w-12 md:flex justify-center items-center rounded-full" key={index}>
                <FontAwesomeIcon icon={faEllipsisH} />
              </div>
            )
          }

          if (pageNumber === numericCurrentPage) {
            return (
              <div className="pagination-item flex w-12 md:flex justify-center items-center rounded-full bg-blue-800 text-white" key={index}>
                {pageNumber}
              </div>
            )
          } else return (
            (pageNumber > 1 ?
              (
                <Link to={`${pageLinkPrefix}/${pageNumber > 1 ? pageNumber : ''}`} key={index}>
                  <div className="pagination-item flex w-12 h-12 md:flex justify-center items-center rounded-full hover:bg-blue-600 hover:text-white hover:md-shadow">
                    {pageNumber}
                  </div>
                </Link>
              )
              : (
                <Link to={pageOneLink} key={index}>
                  <div className="pagination-item flex w-12 h-12 md:flex justify-center items-center rounded-full hover:bg-blue-600 hover:text-white hover:md-shadow">
                    {pageNumber}
                  </div>
                </Link>
              )
            )
          )
        })}

        {/* Right navigation arrow */}
        {
          (numericCurrentPage < totalPages ? 
            (
              <Link to={`${pageLinkPrefix}/${numericCurrentPage + 1}`} itemProp="url" key="right-arrow">
                <div className="pagination-item flex h-12 w-12 rounded-full bg-gray-200 justify-center items-center hover:bg-blue-600 hover:text-white hover:md-shadow">
                  <FontAwesomeIcon icon={faAngleRight} />
                </div>
              </Link>
            )
            : (
              <div className="pagination-item flex h-12 rounded-full bg-gray-200 justify-center items-center" key="right-arrow">
                <FontAwesomeIcon icon={faAngleRight} className="opacity-50"/>
              </div>
            )
          )
        }
      </div>
    </div>


  );
}

export default Pager;